<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar e_chart_sidebar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'Inbox' }" >
                    <router-link :to="{ name: 'Inbox'}"> Faxes Received</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'Inbox'">
				</li>
                <li :class="{ 'active': this.$route.name === 'InboxDeletedList' }" >
                    <router-link :to="{ name: 'InboxDeletedList'}"> Faxes Deleted</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'InboxDeletedList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'FaxesSent' }" >
                    <router-link :to="{ name: 'FaxesSent'}"> Faxes Sent</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'FaxesSent'">
				</li>
				<li :class="{ 'active': this.$route.name === 'SentAFax' }" >
                    <router-link :to="{ name: 'SentAFax'}">  Send A Fax</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'SentAFax'">
				</li>
            </ul>
        </div>
    </div>
</template>