<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies files_overview">
			<div class="a_header pe-0">
				<div>
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" class="form-control" v-model="form.keyword" placeholder="Search Sent Faxes" @keyup.enter="getInboxSentList" @input="debouncedSearch">
					</div>
					<span class="position-relative">
						<button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter</button>
						<button class="comman_btn" @click="addFilter" v-else>Filters | {{ this.filterList.length }}</button>
						<div class="filterOptions-select">
							<CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter"
								@custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getInboxSentList">
							</CustomFilter>
						</div>
					</span>
				</div>
			</div>
			<div class="table-responsive consult-list inbox-table-list">
				<table class="table">
					<thead>
						<tr>
							<th scope="col">Sent Date Time <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col">Fax Subject <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col">Recipient Fax <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
							<th scope="col">Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col" class="hover_unset cursor-default" style="width: calc(var(--scale-ratio) * 140px);"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in this.faxesData" :key="index">
							<td>
								<a target="_blank" class="rcivd_date_line" @click="viewPdf(item)">{{ item.DateSent }}</a>
							</td>
							<td>{{ item.Subject }}</td>
							<td>{{ item.ToFaxNumber }}</td>
							<td><span :class="{'success-lable':item.SentStatus!='Failed', 'failed-lable':item.SentStatus=='Failed'}" >{{ item.SentStatus }}</span></td>
							<td class="edit_delet_link text-start">
								<span class="popup_link"><a target="_blank" @click="viewPdf(item)">View</a></span>
								<span class="popup_link" @click="resendFax(item)" v-if="item.SentStatus=='Failed'"><a target="_blank">Resend</a></span>
							</td>

						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import sidebar from './sidebar.vue';
import CustomFilter from '../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
	data() {
		return {
			filterList:[],
			isShowCustomFilter:false,
			filterOptions:[
				{ value: "received_timerange", title: "Sent Date Time" },
				{ value: "Subject", title: "Fax Subject" },
				{ value: "ToFaxNumber", title: "Recipient Fax" },
				{ value: "SentStatus", title: "Status" },
			],
			faxesData:[],
			form: {
				keyword: "",
				advanceFilter: []
			},
			defaultFormData:{
				keyword: "",
				advanceFilter: []
			}
		}
	},
	components: {
		sidebar,
		CustomFilter
	},
	mounted(){
		this.resetFormData()
		document.addEventListener('click', this.handleClickOutside);
		this.getInboxSentList()
	},
	methods:{
		handleClickOutside(event) {
			if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
				this.getInboxSentList()
				this.isShowCustomFilter = false;
			}

		},
		addFilter(){
			this.isShowCustomFilter = !this.isShowCustomFilter;
		},
		getInboxSentList(){
			this.$store.state.loader = true;
			axios.post("inbox/get-faxes-sent-list", {
				keyword: this.form.keyword,
				advanceFilter: this.form.advanceFilter,
				sort_by: this.sortBy,
				sort_order: this.sortOrder,
			}).then((response) => {
				this.faxesData = response.data.data
				this.$store.state.loader = false;
			}).catch(error => {
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		handleCustomField(values){
			this.filterList = values
			let advanceFilter={}
			values.forEach(item => {
				advanceFilter[item.field] = item.value
			});
			this.form.advanceFilter = advanceFilter
		},
		debouncedSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.getInboxSentList()
			}, 1000);
		},
		resendFax(data){
			this.$store.state.loader = true;
			axios.post("inbox/resend-fax", data).then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200){
					this.$filters.moshaToast("Fax resend successfully", "success")
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		viewPdf(data){
			this.$store.state.loader = true;
			axios.post("inbox/retrieve-pdf", {'FileName':data.FileName}).then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200){
					let responseData = response.data.data
					const pdfData = responseData.startsWith('data:application/pdf;base64,') ? responseData : `data:application/pdf;base64,${responseData}`;
					// Convert the base64 string to a Blob
					const byteCharacters = atob(pdfData.split(',')[1]);

					const byteNumbers = new Array(byteCharacters.length);
					for (let i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}
					const byteArray = new Uint8Array(byteNumbers);
					const blob = new Blob([byteArray], { type: 'application/pdf' });

					// Create an object URL from the Blob
					const objectUrl = URL.createObjectURL(blob);

					// Open the PDF in a new tab
					window.open(objectUrl, '_blank');

				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		resetFormData(){
			Object.assign(
				this.form,
				this.defaultFormData,
			);
		}

	},
	computed: {
	},
}
</script>